import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Card,
  Skeleton,
  Image,
  Empty,
  Button,
  Select,
  Space,
  Badge,
  InputNumber,
  Form,
  Table,
  Slider,
} from "antd";
import type { TableProps } from "antd";
import {
  DownloadOutlined,
  FilterOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { exportToExcel, exportToPDF } from "./FreightList.utils";
import { FreightListProps, IFreight } from "./interfaces/FreightList.interface";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

const CustomEmpty: React.FC = () => (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem registros" />
);

type ColumnType = TableProps<IFreight>["columns"];

const FreightList = forwardRef<HTMLDivElement, FreightListProps>(
  (props, ref) => {
    const { data } = props;
    let navigate = useNavigate();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [containerSizeFilter, setContainerSizeFilter] = useState<string[]>(
      []
    );
    const [carrierFilter, setCarrierFilter] = useState<string[]>([]);
    const [priceRange, setPriceRange] = useState<[number, number]>([0, 100000]);
    const [form] = Form.useForm();
    const [minMaxPrices, setMinMaxPrices] = useState<[number, number]>([
      0, 100000,
    ]);

    const convertStringToNumber = (value: string | number): number => {
      if (typeof value === "number") return value;
      if (!value) return 0;

      // Remove any non-numeric characters except dots and commas
      const cleanValue = value
        .toString()
        .replace(/[^\d.,]/g, "")
        .replace(",", ".") // Convert comma to dot for decimal
        .trim();

      return parseFloat(cleanValue) || 0;
    };

    const renderSkeletonWithText = (width: number = 100) => (
      <div style={{ position: "relative", width: `${width}px` }}>
        <Skeleton.Button active size="small" style={{ width: `${width}px` }} />
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#bfbfbf",
            pointerEvents: "none",
          }}
        >
          Buscando...
        </span>
      </div>
    );

    const calculateTotalValue = (item: IFreight): number => {
      const baseFreight = convertStringToNumber(item.base_freight || 0);
      const bunker = convertStringToNumber(item.bunker || 0);
      const isps = convertStringToNumber(item.isps || 0);

      return baseFreight + bunker + isps;
    };

    const CARRIER_IDS: { [key: string]: number } = {
      MAERSK: 1,
      "CMA-CGM": 2,
      MSC: 3,
      HMM: 4,
      COSCO: 5,
      HAPAG: 6,
      ONE: 7,
      ZIM: 8,
      EVERGREEN: 9,
      PIL: 10,
      GRIMALDI: 11,
      "YANG MING": 12,
    };

    const formatCarrierName = (name: string): string => {
      if (name.toUpperCase() === "CMACGM") {
        return "CMA-CGM";
      }
      return name.toUpperCase();
    };

    const handleBooking = (record: IFreight) => {
      const totalValue = calculateTotalValue(record);
      const carrierName = formatCarrierName(record.type || "");
      const carrierId = CARRIER_IDS[carrierName] || "";
      const imagePath = `/imagens/armador_${carrierId}.png`;

      const path = encodeURI(
        `/booking?armador=${carrierName}&tipo_container=${
          record.container_size || ""
        }&id_armador=${carrierId}&porto_embarque=${
          record.from || ""
        }&porto_descarga=${record.to || ""}&navio=${
          record.vessel || ""
        }&data_embarque=${record.etd || ""}&tempo_de_transito=${
          record.transit_time || ""
        }&data_chegada=${record.eta || ""}&frete=${totalValue}&base=${
          record.base_freight || ""
        }&bunker=${record.bunker || ""}&isps=${
          record.isps || ""
        }&imagem_link=${imagePath}&shipment_id=${record.shipment_id || ""}`
      );

      navigate(path);
    };

    const isNullOrUndefined = (value: any): boolean => {
      return (
        value === null ||
        value === undefined ||
        value === "" ||
        value === "undefined"
      );
    };

    // Filter waiting_bot records considering both the flag and creation time
    const filterWaitingBotRecords = (items: IFreight[]): IFreight[] => {
      if (!items) return [];

      return items.filter((item) => {
        if (!item.waiting_bot) return true;
        if (!item.created_at) return false;

        const createdTime = dayjs(item.created_at);
        const threeMinutesAgo = dayjs().subtract(3, "minutes");

        return createdTime.isAfter(threeMinutesAgo);
      });
    };

    const freightData: IFreight[] = filterWaitingBotRecords(data || []);

    // Calculate min and max prices from data for slider range
    const calculatePriceRange = (items: IFreight[]): [number, number] => {
      if (!items || items.length === 0) return [0, 100000];

      const prices = items
        .filter((item) => !item.waiting_bot)
        .map((item) => calculateTotalValue(item))
        .filter((price) => price > 0);

      if (prices.length === 0) return [0, 100000];

      return [Math.floor(Math.min(...prices)), Math.ceil(Math.max(...prices))];
    };

    useEffect(() => {
      const [min, max] = calculatePriceRange(freightData);
      setMinMaxPrices([0, max]);
      setPriceRange([0, max]);
    }, [data]);

    const renderWithSkeleton = (value: any, width: number = 100) => {
      if (isNullOrUndefined(value)) {
        return renderSkeletonWithText(100);
      }
      return value;
    };

    const renderContainerSizes = (size: string | null | undefined) => {
      if (isNullOrUndefined(size)) {
        return renderSkeletonWithText(120);
      }
      return `${size}'`;
    };

    const renderType = (value: string | null | undefined) => {
      if (isNullOrUndefined(value)) {
        return (
          <Skeleton.Button active size="small" style={{ width: "60px" }} />
        );
      }

      const typeValue = value.toLowerCase();
      const carrierImages: { [key: string]: string } = {
        msc: "/imagens/armadores/Msc.png",
        hapag: "/imagens/armadores/Hapag.png",
        one: "/imagens/armadores/One.png",
        cmacgm: "/imagens/armadores/CmaCgm.png",
        maersk: "/imagens/armadores/Maersk.png",
        zim: "/imagens/armadores/Zim.png",
      };

      if (carrierImages[typeValue]) {
        return (
          <div className="flex items-center gap-2">
            <Image
              src={carrierImages[typeValue]}
              alt={`${value} Logo`}
              preview={false}
              width={50}
              style={{ objectFit: "contain" }}
            />
            <span>{value.toUpperCase()}</span>
          </div>
        );
      }

      return value.toUpperCase();
    };

    const formatCurrency = (value: string | number, record: IFreight) => {
      try {
        if (isNullOrUndefined(record)) {
          return (
            <Skeleton.Button active size="small" style={{ width: "120px" }} />
          );
        }

        const totalValue = calculateTotalValue(record);
        return `${totalValue.toLocaleString("en-US")} USD`;
      } catch (error) {
        console.error("Error formatting currency:", error);
        return "N/A";
      }
    };

    const formatDate = (date: string | null | undefined, record: IFreight) => {
      if (isNullOrUndefined(date)) {
        return renderSkeletonWithText(120);
      }

      // Check if carrier is MSC and ETD equals ETA
      if (record.type?.toLowerCase() === "msc" && record.etd === record.eta) {
        return "TBA";
      }

      try {
        return dayjs(date).format("DD/MM/YYYY");
      } catch {
        return renderSkeletonWithText(120);
      }
    };

    const safeCompare = (a: any, b: any, defaultValue: number = 0): number => {
      if (isNullOrUndefined(a) || isNullOrUndefined(b)) return defaultValue;
      return String(a).localeCompare(String(b));
    };

    const safeDateCompare = (
      a: any,
      b: any,
      defaultValue: number = 0
    ): number => {
      if (isNullOrUndefined(a) || isNullOrUndefined(b)) return defaultValue;
      try {
        return dayjs(a).unix() - dayjs(b).unix();
      } catch {
        return defaultValue;
      }
    };

    const uniqueCarriers = Array.from(
      new Set(freightData.map((item) => item.type?.toLowerCase()))
    ).filter(Boolean);

    const columns: ColumnType = [
      {
        title: "Armador",
        dataIndex: "type",
        key: "type",
        render: renderType,
        sorter: (a, b) => safeCompare(a.type, b.type),
        width: 250,
      },
      {
        title: "Origem",
        dataIndex: "from",
        key: "from",
        render: (value) => renderWithSkeleton(value, 120),
        sorter: (a, b) => safeCompare(a.from, b.from),
      },
      {
        title: "Tamanho do Container",
        dataIndex: "container_size",
        key: "container_size",
        render: renderContainerSizes,
        sorter: (a, b) => safeCompare(a.container_size, b.container_size),
      },
      {
        title: "Destino",
        dataIndex: "to",
        key: "to",
        render: (value) => renderWithSkeleton(value, 120),
        sorter: (a, b) => safeCompare(a.to, b.to),
      },
      {
        title: "Valor",
        dataIndex: "value",
        key: "value",
        render: (_, record) => {
          if (record.waiting_bot) {
            return renderSkeletonWithText(120);
          }
          return formatCurrency(0, record);
        },
        sorter: (a, b) => {
          const valueA = calculateTotalValue(a);
          const valueB = calculateTotalValue(b);
          return valueA - valueB;
        },
      },
      {
        title: "ETD",
        dataIndex: "etd",
        key: "etd",
        render: (value, record) => formatDate(value, record),
        sorter: (a, b) => safeDateCompare(a.etd, b.etd),
      },
      {
        title: "ETA",
        dataIndex: "eta",
        key: "eta",
        render: (value, record) => formatDate(value, record),
        sorter: (a, b) => safeDateCompare(a.eta, b.eta),
      },
      {
        title: "Tempo de Trânsito",
        dataIndex: "transit_time",
        key: "transit_time",
        render: (value) => renderWithSkeleton(value, 100),
        sorter: (a, b) => safeCompare(a.transit_time, b.transit_time),
      },
      {
        title: "Booking",
        key: "action",
        width: 120,
        render: (_, record) => {
          if (record.waiting_bot) {
            return renderSkeletonWithText(120);
          }

          if (
            record.from === "TBI" ||
            record.transit_time === "TBA" ||
            record.base_freight === "No space available"
          ) {
            return <div className="info">-</div>;
          }

          return (
            <Button
              type="primary"
              icon={<ShoppingCartOutlined />}
              onClick={() => handleBooking(record)}
              size="middle"
            >
              Book now
            </Button>
          );
        },
      },
    ];

    const filteredData = freightData.filter((item) => {
      const matchesContainerSize =
        containerSizeFilter.length === 0 ||
        (item.container_size &&
          containerSizeFilter.includes(item.container_size));

      const matchesCarrier =
        carrierFilter.length === 0 ||
        (item.type && carrierFilter.includes(item.type.toLowerCase()));

      const totalValue = calculateTotalValue(item);
      const matchesPrice =
        item.waiting_bot ||
        (totalValue >= priceRange[0] && totalValue <= priceRange[1]);

      return matchesContainerSize && matchesCarrier && matchesPrice;
    });

    const FilterCard = () => (
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Button
              icon={<FilterOutlined />}
              onClick={() => setIsFilterVisible(!isFilterVisible)}
              type="text"
            >
              {isFilterVisible ? "Ocultar filtros" : "Mostrar filtros"}
            </Button>
            <Badge
              count={filteredData.length}
              overflowCount={9999}
              style={{ backgroundColor: "#52c41a" }}
            />
          </div>
        }
        style={{ marginBottom: 16, marginTop: 20 }}
      >
        {isFilterVisible && (
          <Form form={form} layout="vertical" style={{ width: "100%" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "16px",
              }}
            >
              <Form.Item
                label="Tamanho do Container"
                style={{ marginBottom: "24px" }}
              >
                <Select
                  placeholder="Selecione o tamanho"
                  style={{ width: "100%" }}
                  mode="multiple"
                  allowClear
                  value={containerSizeFilter}
                  onChange={(value) => setContainerSizeFilter(value)}
                  options={[
                    { value: "20", label: "20'" },
                    { value: "40", label: "40'" },
                  ]}
                />
              </Form.Item>
              <Form.Item label="Armador" style={{ marginBottom: "24px" }}>
                <Select
                  placeholder="Selecione o armador"
                  style={{ width: "100%" }}
                  mode="multiple"
                  allowClear
                  value={carrierFilter}
                  onChange={(value) => setCarrierFilter(value)}
                  options={uniqueCarriers.map((carrier) => ({
                    value: carrier,
                    label: carrier?.toUpperCase(),
                  }))}
                  maxTagCount="responsive"
                />
              </Form.Item>
            </div>
            <Form.Item
              label="Faixa de Valor (USD)"
              style={{ marginBottom: "8px" }}
            >
              <div style={{ padding: "0 8px" }}>
                <Slider
                  range
                  min={minMaxPrices[0]}
                  max={minMaxPrices[1]}
                  value={priceRange}
                  onChange={(value: [number, number]) => setPriceRange(value)}
                  tipFormatter={(value) => `$${value?.toLocaleString()}`}
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 8,
                    color: "#666",
                  }}
                >
                  <span>${priceRange[0].toLocaleString()}</span>
                  <span>${priceRange[1].toLocaleString()}</span>
                </div>
              </div>
            </Form.Item>
          </Form>
        )}
      </Card>
    );

    return (
      <>
        <FilterCard />
        <div ref={ref}>
          <Card
            title="Lista de Fretes"
            style={{ width: "100%" }}
            extra={
              <Space>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => exportToExcel(filteredData)}
                  type="primary"
                  ghost
                >
                  Exportar Excel
                </Button>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => exportToPDF(filteredData)}
                  type="primary"
                  ghost
                >
                  Exportar PDF
                </Button>
              </Space>
            }
          >
            <Table<IFreight>
              dataSource={filteredData}
              columns={columns}
              locale={{
                emptyText: <CustomEmpty />,
              }}
              rowKey="user_id"
              pagination={{
                pageSize: 100,
                showSizeChanger: true,
                showTotal: (total: number) => `Total de ${total} registros`,
              }}
              scroll={{ x: true }}
            />
          </Card>
        </div>
      </>
    );
  }
);

export default FreightList;
