import * as firebase from "firebase";
import "firebase/database";

let config = {
  apiKey: "AIzaSyAhmSre0K0_Bsec8PT9noEA_W4-vXICFr8",
  authDomain: "zarpar-2c15c.firebaseapp.com",
  databaseURL: "https://zarpar-2c15c-default-rtdb.firebaseio.com",
  projectId: "zarpar-2c15c",
  storageBucket: "zarpar-2c15c.firebasestorage.app",
  messagingSenderId: "831036526113",
  appId: "1:831036526113:web:b1d4b222b60d6c84695d60",
  measurementId: "G-3RDLX16SLV"
};

firebase.initializeApp(config);

export default firebase.database();
