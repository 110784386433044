import React from "react";
import { Table, Collapse, Tag, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";

const { Panel } = Collapse;
const { Title, Text } = Typography;

type ErrorRecord = {
  linha: number;
  erro: string;
  dados: any;
};

type ResultadoProps = {
  response: {
    arquivo: string;
    total_registros: number;
    total_importados: number;
    total_erros: number;
    total_duplicados_planilha: number;
    total_duplicados_banco: number;
    erros: ErrorRecord[];
    success: boolean;
  };
};

const TabelaResultados = (props: ResultadoProps) => {
  // Separar os diferentes tipos de registros
  const registrosDuplicadosPlanilha = props.response.erros.filter(
    (record) => record.erro === "Registro duplicado na planilha"
  );
  const registrosDuplicadosBanco = props.response.erros.filter(
    (record) => record.erro === "Registro já existe no banco de dados"
  );
  const registrosComErro = props.response.erros.filter(
    (record) =>
      !record.erro.includes("duplicado") &&
      !record.erro.includes("existe no banco")
  );

  const errorColumns: ColumnsType<ErrorRecord> = [
    {
      title: "Linha",
      dataIndex: "linha",
      key: "linha",
      width: 100,
    },
    {
      title: "Erro",
      dataIndex: "erro",
      key: "erro",
      width: 300,
    },
    {
      title: "Dados do Registro",
      dataIndex: "dados",
      key: "dados",
      render: (dados) => (
        <pre className="whitespace-pre-wrap break-all">
          {JSON.stringify(dados, null, 2)}
        </pre>
      ),
    },
  ];

  return (
    <div className="space-y-4 mt-8">
      <div className="bg-white p-6 rounded-lg shadow">
        <Title
          level={2}
          className={props.response.success ? "text-green-600" : "text-red-600"}
        >
          {props.response.success
            ? "Arquivo Importado com Sucesso!"
            : "Problemas na Importação"}
        </Title>

        <Table
          className="mt-4"
          pagination={false}
          bordered
          dataSource={[
            {
              key: "1",
              armador: props.response.arquivo,
              total: props.response.total_registros,
              importados: props.response.total_importados,
              erros:
                props.response.total_erros -
                props.response.total_duplicados_planilha -
                props.response.total_duplicados_banco,
              duplicadosPlanilha: props.response.total_duplicados_planilha,
              duplicadosBanco: props.response.total_duplicados_banco,
            },
          ]}
          columns={[
            {
              title: "Armador",
              dataIndex: "armador",
              key: "armador",
            },
            {
              title: "Total de Registros",
              dataIndex: "total",
              key: "total",
            },
            {
              title: "Importados",
              dataIndex: "importados",
              key: "importados",
              render: (value) => <Tag color="green">{value}</Tag>,
            },
            {
              title: "Erros",
              dataIndex: "erros",
              key: "erros",
              render: (value) => <Tag color="red">{value}</Tag>,
            },
            {
              title: "Duplicados na Planilha",
              dataIndex: "duplicadosPlanilha",
              key: "duplicadosPlanilha",
              render: (value) => <Tag color="orange">{value}</Tag>,
            },
            {
              title: "Duplicados no Banco",
              dataIndex: "duplicadosBanco",
              key: "duplicadosBanco",
              render: (value) => <Tag color="purple">{value}</Tag>,
            },
          ]}
        />
      </div>

      {(registrosComErro.length > 0 ||
        registrosDuplicadosPlanilha.length > 0 ||
        registrosDuplicadosBanco.length > 0) && (
        <div className="bg-white p-6 rounded-lg shadow">
          <Collapse>
            {registrosComErro.length > 0 && (
              <Panel
                header={
                  <Text strong className="text-red-500">
                    Registros com Erro ({registrosComErro.length})
                  </Text>
                }
                key="1"
              >
                <Table
                  columns={errorColumns}
                  dataSource={registrosComErro.map((erro, index) => ({
                    ...erro,
                    key: `erro-${index}`,
                  }))}
                  scroll={{ x: "max-content" }}
                  pagination={{ pageSize: 5 }}
                />
              </Panel>
            )}

            {registrosDuplicadosPlanilha.length > 0 && (
              <Panel
                header={
                  <Text strong className="text-orange-500">
                    Registros Duplicados na Planilha (
                    {registrosDuplicadosPlanilha.length})
                  </Text>
                }
                key="2"
              >
                <Table
                  columns={errorColumns}
                  dataSource={registrosDuplicadosPlanilha.map(
                    (duplicado, index) => ({
                      ...duplicado,
                      key: `duplicado-planilha-${index}`,
                    })
                  )}
                  scroll={{ x: "max-content" }}
                  pagination={{ pageSize: 5 }}
                />
              </Panel>
            )}

            {registrosDuplicadosBanco.length > 0 && (
              <Panel
                header={
                  <Text strong className="text-purple-500">
                    Registros já Existentes no Banco (
                    {registrosDuplicadosBanco.length})
                  </Text>
                }
                key="3"
              >
                <Table
                  columns={errorColumns}
                  dataSource={registrosDuplicadosBanco.map(
                    (duplicado, index) => ({
                      ...duplicado,
                      key: `duplicado-banco-${index}`,
                    })
                  )}
                  scroll={{ x: "max-content" }}
                  pagination={{ pageSize: 100 }}
                />
              </Panel>
            )}
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default TabelaResultados;
