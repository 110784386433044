import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_END_POINT_API || "https://api.zarpar-beta.net",
});

api.interceptors.request.use(
  (config) => {
    config.headers["ngrok-skip-browser-warning"] = "true";
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
