import React, { useState, useRef } from "react";
import HeaderPage from "../HeaderPage";
import Sidebar from "../Sidebar";
import FreightForm from "./components/forms/FreightForm";
import FreightList from "./components/table/FreightList";
import QuotesFirebaseDataService from "../../firebase/service/quotes.firebase.data.service";
import FreightScheduleList from "./components/table/FreightScheduleList";
import moment from "moment";

const QuotesEnginnering = () => {
  const [quotes, setQuotes] = useState([]);
  const [quotesSchedule, setQuotesSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const quotesRef = useRef<firebase.database.Reference | null>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const filterByPortCodeAndDate = (
    quotesArray: any[],
    value: any,
    baseDate: string
  ) => {
    const baseDateMoment = moment(baseDate);
    const minDate = moment(baseDateMoment).subtract(5, "days");
    const maxDate = moment(baseDateMoment).add(30, "days");

    return quotesArray
      .filter((item) => {
        const matchesPorts =
          item.from_code === value.port_from_code &&
          item.to_code === value.port_to_code;
        const itemDate = moment(item.etd);
        const isWithinDateRange = itemDate.isBetween(
          minDate,
          maxDate,
          "day",
          "[]"
        );
        return matchesPorts;
      })
      .sort((a, b) => (a.waiting_bot === true ? -1 : 1));
  };

  const handleDataChange = (items: any, value: any, filters?: any) => {
    try {
      const quotesArray: any[] = [];
      items.forEach((item: any) => {
        const data = item.val();
        quotesArray.push(data);
      });

      let filteredData = filterByPortCodeAndDate(
        quotesArray,
        value,
        value.date
      );

      if (filters) {
        const { containerSizeFilter, carrierFilter, priceRange } = filters;
        filteredData = filteredData.filter((item) => {
          const matchesSize =
            containerSizeFilter.length === 0 ||
            containerSizeFilter.includes(item.container_size);
          const matchesCarrier =
            carrierFilter.length === 0 ||
            carrierFilter.includes(item.type?.toLowerCase());
          const price = getPriceValue(item.value);
          const matchesPrice = price >= priceRange[0] && price <= priceRange[1];
          return matchesSize && matchesCarrier && matchesPrice;
        });
      }

      setQuotes(filteredData);
      setLoading(false);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error("Erro ao processar dados")
      );
      setLoading(false);
    }
  };

  const getPriceValue = (priceString: string | number): number => {
    if (typeof priceString === "number") return priceString;
    const cleanValue =
      priceString?.replace(/USD/g, "").replace(/,/g, "").trim() || "0";
    const numbersOnly = cleanValue.replace(/[^\d.]/g, "");
    return parseFloat(numbersOnly) || 0;
  };

  const onFinish = async (value: any, filters?: any) => {
    if (quotesRef.current) {
      quotesRef.current.off("value");
    }

    const quotesDataService = new QuotesFirebaseDataService(
      value.date_firebase
    );
    quotesRef.current = quotesDataService.getAll();
    quotesRef.current.on("value", (items) =>
      handleDataChange(items, value, filters)
    );
    setQuotesSchedule(value.data.nextFreight);
    setTimeout(
      () => tableRef.current?.scrollIntoView({ behavior: "smooth" }),
      100
    );
  };

  return (
    <div className="flex-dashboard">
      <Sidebar elementoAtivo="quotesenginner" />
      <main>
        <HeaderPage nomeOpcao="Cotaçao SPOT - BETA" />
        <div className="main-content">
          <div className="main-content-title">
            <div>
              <FreightForm onFinish={onFinish} />
              <FreightList ref={tableRef} data={quotes} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default QuotesEnginnering;
