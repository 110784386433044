import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Typography,
  Modal,
} from "antd";
import api from "../../../../services/api";
import dayjs from "dayjs";
import apiBeta from "../../../../services/api-beta";

interface FreightSearchFormProps {
  onFinish: (values: any) => void;
}

const { Title, Text } = Typography;

interface ItemSelect {
  idItem: any;
  name: string;
}

interface PortoSelect {
  port_code: string;
  port_id: string;
  port_name: string;
}

const FreightSearchForm: React.FC<FreightSearchFormProps> = ({ onFinish }) => {
  const [form] = Form.useForm();
  const [tiposMercadoria, setTiposMercadoria] = useState<ItemSelect[]>([]);
  const [portosEmbarque, setPortosEmbarque] = useState<PortoSelect[]>([]);
  const [portosDescarga, setPortosDescarga] = useState<PortoSelect[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.get("filters/portos_embarque").then((response) => {
      setPortosEmbarque(response.data);
    });

    api.get("filters/portos_descarga").then((response) => {
      setPortosDescarga(response.data);
    });

    api.get("filters/tipos_mercadoria").then((response) => {
      setTiposMercadoria(response.data);
    });
  }, []);

  const handleSubmit = async (values: any) => {
    const userId = localStorage.getItem("user_id");

    if (!userId || userId === "undefined") {
      Modal.warning({
        title: "Sessão Antiga Detectada",
        content:
          "Foi verificado que você possui uma sessão antiga do sistema. Para utilizar esse novo recurso é necessário deslogar e logar novamente.",
        okText: "Entendi",
      });
      return;
    }

    try {
      setLoading(true);

      const selectedFromPort = portosEmbarque.find(
        (porto) => porto.port_id === values.portoEmbarque
      );

      const selectedToPort = portosDescarga.find(
        (porto) => porto.port_id === values.portoDescarga
      );

      const formattedDate = dayjs(values.dataEmbarque).format("YYYY-MM-DD");
      const formattedDateFirebase = dayjs(values.dataEmbarque).format(
        "YYYY-MM"
      );

      const token = localStorage.getItem("access_token");

      const requestData = {
        from_code: selectedFromPort?.port_id,
        to_code: selectedToPort?.port_id,
        date: formattedDate,
      };

      const response = await apiBeta.post(`/quotes-freight/new`, requestData, {
        headers: {
          Authorization: `${token}`,
          "ngrok-skip-browser-warning": "true",
          "Content-Type": "application/json",
        },
      });

      onFinish({
        ...response.data,
        ...{
          date: formattedDate,
          date_firebase: formattedDateFirebase,
          port_from_code: selectedFromPort?.port_code,
          port_to_code: selectedToPort?.port_code,
        },
      });
    } catch (error) {
      console.error("Erro ao buscar fretes:", error);
    } finally {
      setLoading(false);
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Card className="w-full rounded-none" bordered={false}>
      <div className="w-full">
        <div className="mb-6">
          <Title level={4} className="mb-2">
            Busca de Fretes Marítimos
          </Title>
          <Text className="text-gray-500">
            Por favor, preencha os detalhes abaixo para encontrar as melhores
            opções de frete para sua carga. Todos os campos marcados com * são
            obrigatórios.
          </Text>
        </div>

        <Form
          {...formItemLayout}
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          className="w-full"
        >
          <Row gutter={[24, 0]} className="w-full">
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="tipoMercadoria"
                label="Tipo de mercadoria *"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="Selecione o tipo de mercadoria"
                  showSearch={true}
                >
                  {tiposMercadoria.map((tipoMercadoria) => (
                    <Select.Option
                      key={tipoMercadoria.idItem}
                      value={tipoMercadoria.idItem}
                    >
                      {tipoMercadoria.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="portoEmbarque"
                label="Porto de embarque *"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="Selecione o porto de embarque"
                  showSearch={true}
                >
                  {portosEmbarque.map((embarque) => (
                    <Select.Option
                      key={embarque.port_id}
                      value={embarque.port_id}
                    >
                      {embarque.port_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="portoDescarga"
                label="Porto de descarga *"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select
                  placeholder="Selecione o porto de descarga"
                  showSearch={true}
                >
                  {portosDescarga.map((descarga) => (
                    <Select.Option
                      key={descarga.port_id}
                      value={descarga.port_id}
                    >
                      {descarga.port_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="dataEmbarque"
                label="Data de embarque *"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  className="w-full"
                  placeholder="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="mt-6">
            <Col span={24} className="flex justify-end">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-blue-500 hover:bg-blue-600"
                  size="large"
                  loading={loading}
                >
                  {loading ? "Buscando..." : "Buscar Fretes"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="mt-4">
          <Text type="secondary" className="text-sm">
            Após preencher todos os campos, clique em "Buscar Fretes" para ver
            as opções disponíveis. O tempo médio de busca é de 30 segundos.
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default FreightSearchForm;
