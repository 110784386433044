import jsPDF from "jspdf";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import "jspdf-autotable";

const formatCurrency = (value: string | number) => {
  try {
    if (typeof value === "number") {
      value = value.toString();
    }

    // Remove "USD" e espaços
    const cleanValue = value.replace(/USD/g, "").trim();

    // Remove quaisquer caracteres não numéricos exceto ponto decimal
    const numbersOnly = cleanValue.replace(/[^\d.]/g, "");

    // Se a string estiver vazia após limpeza
    if (!numbersOnly) {
      return "N/A";
    }

    // Se o número tem decimais (ex: "2.926"), multiplica por 1000 para preservar o valor
    if (numbersOnly.includes(".")) {
      const number = Math.round(parseFloat(numbersOnly) * 1000);
      return `${number.toLocaleString("en-US")} USD`;
    }

    // Se o número já está sem decimais (ex: "2926")
    const number = parseInt(numbersOnly, 10);
    return `${number.toLocaleString("en-US")} USD`;
  } catch (error) {
    return "N/A";
  }
};

export const exportToPDF = (filteredData: any) => {
  try {
    const doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(16);
    doc.text("Lista de Fretes", 14, 15);
    doc.setFontSize(10);
    doc.text(`Exportado em: ${dayjs().format("DD/MM/YYYY HH:mm")}`, 14, 22);

    const exportData = filteredData.map((item) => [
      item.type?.toUpperCase() || "",
      item.from || "",
      item.to || "",
      item.container_size ? `${item.container_size}'` : "",
      item.value ? formatCurrency(item.value) : "",
      item.etd ? dayjs(item.etd).format("DD/MM/YYYY") : "",
      item.eta ? dayjs(item.eta).format("DD/MM/YYYY") : "",
      item.transit_time || "",
    ]);

    (doc as any).autoTable({
      head: [
        [
          "Armador",
          "Origem",
          "Destino",
          "Tamanho do Container",
          "Valor",
          "ETD",
          "ETA",
          "Tempo de Trânsito",
        ],
      ],
      body: exportData,
      startY: 25,
      theme: "grid",
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
      headStyles: {
        fillColor: [51, 122, 183],
        textColor: 255,
        fontSize: 8,
        fontStyle: "bold",
      },
      columnStyles: {
        0: { cellWidth: 25 }, // Armador
        1: { cellWidth: 35 }, // Origem
        2: { cellWidth: 35 }, // Destino
        3: { cellWidth: 20 }, // Tamanho do Container
        4: { cellWidth: 25 }, // Valor
        5: { cellWidth: 20 }, // ETD
        6: { cellWidth: 20 }, // ETA
        7: { cellWidth: 25 }, // Tempo de Trânsito
      },
    });
    doc.save(`lista-fretes-${dayjs().format("DDMMYYYY")}.pdf`);
  } catch (error) {
    console.error("Erro na exportação PDF:", error);
  }
};

export const exportToExcel = (filteredData: any) => {
  try {
    const exportData = filteredData.map((item) => ({
      Armador: item.type?.toUpperCase() || "",
      Origem: item.from || "",
      Destino: item.to || "",
      "Tamanho do Container": item.container_size
        ? `${item.container_size}'`
        : "",
      Valor: item.value ? formatCurrency(item.value).toString() : "",
      ETD: item.etd ? dayjs(item.etd).format("DD/MM/YYYY") : "",
      ETA: item.eta ? dayjs(item.eta).format("DD/MM/YYYY") : "",
      "Tempo de Trânsito": item.transit_time || "",
    }));

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Fretes");

    const colWidths = [
      { wch: 15 }, // Armador
      { wch: 20 }, // Origem
      { wch: 20 }, // Destino
      { wch: 15 }, // Tamanho do Container
      { wch: 15 }, // Valor
      { wch: 12 }, // ETD
      { wch: 12 }, // ETA
      { wch: 15 }, // Tempo de Trânsito
    ];
    ws["!cols"] = colWidths;

    XLSX.writeFile(wb, `lista-fretes-${dayjs().format("DDMMYYYY")}.xlsx`);
  } catch (error) {
    console.error("Erro na exportação Excel:", error);
  }
};
